import React from 'react';
import './_promo_card_tag.scss';


const PromoCardTag = ({ tagName, theme }) => {
  return (
    <div className={`promo-card-tag promo-card-tag--${theme.replace(/\s/g, '').toLowerCase()}`}>
      <div className={`tag-name tag-name--${theme.replace(/\s/g, '').toLowerCase()}`}>
          {tagName}  
      </div>
    </div>
  );
};

export default PromoCardTag;
